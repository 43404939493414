import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Select from "react-select";
import { NumericFormat } from "react-number-format";
import { GetCountries, GetState } from "react-country-state-city";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  Calendar3,
  CreditCard2Back,
  CreditCard2Front,
} from "react-bootstrap-icons";

import { NotificationManager } from "react-notifications";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import {
  emailValidator,
  emailWithConPatternValidator,
} from "../../../../utils/validationFn";
import EncryptionIcon from "./EncryptionIcon";
import { removeSpecialCharacters } from "../../../../utils/removeSpecialCharacters";

const min_phone_length = 3;
const max_phone_length = 25;
const cardStyle = {
  hidePostalCode: true,
  style: {
    base: {
      fontSize: "16px",
      color: "#424770",
      "::placeholder": { color: "#aab7c4" },
    },
    invalid: { color: "#9e2146", textAlign: "left" },
  },
};

const AlertModal = ({ show, onClose, children }) => {
  const handleClose = () => {
    onClose(true);
  };

  return (
    <Modal
      show={show}
      keyboard={false}
      onHide={handleClose}
      size='lg'
      centered
      backdrop='static'
    >
      {children}
    </Modal>
  );
};

// Function: callback
// Why? If you want to update the price overview, put this function to the dedicate place
// Example:
// (1) You want to update the tax amount after user input country and zip code
const PaymentForm = ({ plan, store_slug, price_slug, coupon, callback }) => {
  // Modal
  let alertElement;
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const handleClose = () => {
    setShow(false);
    setModalContent(null);
  };

  const navigate = useNavigate();
  const [isEnableCheckoutButton, setIsEnableCheckoutButton] = useState(false);
  const [cardError, setCardError] = useState(null);

  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const stateRef = useRef();

  const stripe = useStripe();
  const elements = useElements();

  const paymentObj = {
    name: "",
    email: "",
    country: "",
    country_iso2: "",
    postal_code: "",
    state: "",
    phone_code: "",
    phone_number: "",
    authorized: false,
    authorized_at: new Date(),
    user_agent: navigator.userAgent,
    utm_source: new URLSearchParams(window.location.search).get("utm_source"),
    utm_medium: new URLSearchParams(window.location.search).get("utm_medium"),
    utm_campaign: new URLSearchParams(window.location.search).get(
      "utm_campaign"
    ),
    utm_term: new URLSearchParams(window.location.search).get("utm_term"),
    utm_content: new URLSearchParams(window.location.search).get("utm_content"),
  };

  const [obj, setObj] = useState(paymentObj);
  const [isEmailConPatter, setIsEmailConPatter] = useState(false);

  // Purpose: to display error message on the field if there is any
  const [isTouched, setIsTouched] = useState({
    name: false,
    email: false,
    phone_number: false,
  });

  // Purpose: to enable submit button
  // include only items to be validated
  const [isValid, setIsValid] = useState({
    name: false,
    email: false,
    country: false,
    state: false,
    phone_number: false,
    authorized: false,
  });

  // manage vanilla html
  const handleChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value ? value : "";

    let isValid = false;
    if (name === "email") {
      inputValue = inputValue.toLowerCase();

      if (emailWithConPatternValidator(inputValue)) {
        isValid = false;
        setIsEmailConPatter(true);
      } else {
        setIsEmailConPatter(false);
        isValid = emailValidator(inputValue);
      }
    } else {
      isValid = Boolean(value);
    }

    setObj((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));

    setIsValid((prevState) => ({
      ...prevState,
      [name]: isValid,
    }));
  };

  const handleBlur = (e) => {
    const { name } = e.target;

    setIsTouched((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  // manage react-select
  const handleSelectChange = (selectOptions, name) => {
    if (name === "country") {
      if (stateRef.current) {
        stateRef.current.clearValue();
      }

      setObj((prevState) => ({
        ...prevState,
        country_iso2: selectOptions.iso2,
        country: selectOptions.name,
        phone_code: selectOptions.phone_code,
        state: "",
      }));

      // Manage countries / region with states but is okay to not collect
      const excluded_lists = ["Singapore"];
      if (excluded_lists.includes(selectOptions.name)) {
        setStateOptions([]);

        setIsValid((prevState) => ({
          ...prevState,
          country: Boolean(selectOptions.name),
          state: true,
        }));

        return;
      }

      setIsValid((prevState) => ({
        ...prevState,
        [name]: Boolean(selectOptions.name),
      }));

      // populate state lists
      GetState(selectOptions.value).then((results) => {
        const stateLists = results.map((result, index) => {
          return {
            index: index,
            value: result.id,
            label: result.name,
            name: result.name,
            state_code: result.state_code,
          };
        });

        // Manage countries / region without state
        // set the state to valid so that submit button can be enable
        if (!stateLists.length) {
          setStateOptions([]);

          setIsValid((prevState) => ({
            ...prevState,
            state: true,
          }));

          return;
        }

        // Manage countries / region with state
        // set the state to invalid so that submit button can be disable
        setStateOptions(stateLists);
        setIsValid((prevState) => ({
          ...prevState,
          state: false,
        }));
      });
    } else if (name === "state") {
      // when change country, state wil return null
      // Break out of loop immediately
      // Otherwise, it will throw error.
      if (!selectOptions) return;

      setObj((prevState) => ({
        ...prevState,
        state: selectOptions.name,
      }));

      setIsValid((prevState) => ({
        ...prevState,
        [name]: Boolean(selectOptions.name),
      }));
    } else {
      setObj((prevState) => ({
        ...prevState,
        [name]: Array.isArray(selectOptions)
          ? selectOptions
          : selectOptions.value,
      }));
    }
  };

  // manage react-number-format
  const handleNumericChange = (val, name) => {
    const { floatValue, formattedValue } = val;

    if (name === "phone_number") {
      setObj((prevState) => ({
        ...prevState,
        [name]: formattedValue,
      }));

      setIsValid((prevState) => ({
        ...prevState,
        [name]: formattedValue.toString().length >= min_phone_length,
      }));

      return;
    }

    const value = parseInt(Math.round(floatValue));

    setObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setIsValid((prevState) => ({
      ...prevState,
      [name]: Boolean(value),
    }));
  };

  const handleNumericBlur = (name) => {
    setIsTouched((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  const handleCheck = (e) => {
    setObj((prevState) => ({
      ...prevState,
      authorized: e.target.checked,
      authorized_at: new Date(),
    }));
    setIsValid((prevState) => ({
      ...prevState,
      authorized: e.target.checked,
    }));
  };

  // Submit payment to server for processing
  const paymentHandler = async (e) => {
    e.preventDefault();

    setCardError(null);
    setIsProcessingPayment(true);

    try {
      let reqBody = {};

      if (coupon) {
        reqBody = {
          coupon: removeSpecialCharacters(coupon),
        };
      }

      const ppay_payment_gateway = plan?.payment_gateways.find(
        (gateway) => gateway.vendor === "stripe"
      );
      reqBody = {
        ...reqBody,
        ...obj,
        payment_account_id: ppay_payment_gateway
          ? ppay_payment_gateway._id
          : null,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v2/stores/${store_slug}/payment-processing/${price_slug}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(reqBody),
        }
      );

      const data = await response.json();

      const { errors, payloads } = data;

      if (errors) {
        const contactElement = (
          <p>
            If you need more clarification, please contact us at{" "}
            <a href='mailto:support@piranhaprofits.com'>
              support@piranhaprofits.com
            </a>{" "}
            for assistance.
          </p>
        );

        // Error fields
        const price_plan_out_of_stock_errors = errors.filter(
          (error) => error.field === "price_plan.out_of_stock"
        );

        const coupon_permission_errors = errors.filter(
          (error) => error.field === "coupon.permission"
        );

        const pricing_errors = errors.filter(
          (error) => error.field === "pricing.grand_total"
        );

        const payment_gateway_errors = errors.filter(
          (error) => error.field === "payment_gateway.vendor"
        );

        const postal_code_errors = errors.filter(
          (error) => error.field === "postal_code"
        );

        if (price_plan_out_of_stock_errors.length) {
          const errorElements = price_plan_out_of_stock_errors.map(
            (error, index) => {
              return (
                <li key={index} className='mx-4 my-3'>
                  <span className='fw-bold'>{error.price_name}</span>
                  <br />
                  <span className='text-muted fst-italic'>
                    Note: {error.message}
                  </span>
                </li>
              );
            }
          );

          alertElement = (
            <>
              <Modal.Header></Modal.Header>
              <Modal.Body>
                <p className='text-center m-0'>
                  <img
                    src='https://media.istockphoto.com/id/949910758/vector/sold-out-ink-stamp.jpg?s=612x612&w=0&k=20&c=Y5cxXYEMGBaEDHVQ7zGwUpwj-Y27sDU3ws5YF_rqEfk='
                    width={200}
                  />
                </p>

                <ol>{errorElements}</ol>

                {contactElement}
              </Modal.Body>
              <Modal.Footer>
                {plan?.preorder_plan?._id ? (
                  <Button
                    onClick={() => {
                      window.location.replace(
                        `/v2/stores/${store_slug}/plans/${plan?.preorder_plan?.slug}`
                      );
                    }}
                    size='lg'
                    style={{ border: "#3CAD66", backgroundColor: "#3CAD66" }}
                  >
                    I want to pre-order.
                  </Button>
                ) : (
                  ""
                )}
              </Modal.Footer>
            </>
          );

          setShow(true);
          setModalContent(alertElement);
        } else if (coupon_permission_errors.length) {
          const errorElements = coupon_permission_errors.map((error, index) => {
            return (
              <li key={index} className='mx-4 my-3'>
                <span className='fw-bold'>{error.price_name}</span>
                <br />
                <span className='text-muted fst-italic'>
                  Note: {error.message}
                </span>
              </li>
            );
          });

          alertElement = (
            <>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <p>
                  This coupon code {coupon ? `(${coupon})` : ""} cannot be
                  applied to the following items due to eligibility
                  requirements. Please contact us at
                  <a href='mailto:support@piranhaprofits.com'>
                    support@piranhaprofits.com
                  </a>{" "}
                  for assistance.
                </p>

                <ol>{errorElements}</ol>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={handleClose}
                  size='lg'
                  style={{ border: "#3CAD66", backgroundColor: "#3CAD66" }}
                >
                  Okay
                </Button>
              </Modal.Footer>
            </>
          );

          setShow(true);
          setModalContent(alertElement);
        } else if (pricing_errors.length) {
          const errorElements = pricing_errors.map((error, index) => {
            return (
              <li key={index} className='mx-4 my-3'>
                <span className='fw-bold'>{error.price_name}</span>
                <br />
                <span className='text-muted fst-italic'>
                  Note: {error.message}
                </span>
              </li>
            );
          });

          alertElement = (
            <>
              <Modal.Header closeButton>
                <p className='fw-bold text-uppercase'>Pricing Errors:</p>
              </Modal.Header>
              <Modal.Body>
                <ol className='p-0'>{errorElements}</ol>

                {contactElement}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={handleClose}
                  size='lg'
                  style={{ border: "#3CAD66", backgroundColor: "#3CAD66" }}
                >
                  Okay
                </Button>
              </Modal.Footer>
            </>
          );

          setShow(true);
          setModalContent(alertElement);
        } else if (payment_gateway_errors.length) {
          NotificationManager.error(payment_gateway_errors[0].message);
        } else if (postal_code_errors.length) {
          NotificationManager.error(postal_code_errors[0].message);
        } else {
          alertElement = (
            <>
              <Modal.Header closeButton>
                <p className='fw-bold text-uppercase'>Error</p>
              </Modal.Header>
              <Modal.Body>
                <p>Something went wrong. Please try again later.</p>

                <p>
                  If problem presists, please contact us at{" "}
                  <a href='mailto:support@piranhaprofits.com'>
                    support@piranhaprofits.com
                  </a>{" "}
                  for assistance.
                </p>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={handleClose}
                  size='lg'
                  style={{ border: "#3CAD66", backgroundColor: "#3CAD66" }}
                >
                  Okay
                </Button>
              </Modal.Footer>
            </>
          );

          setShow(true);
          setModalContent(alertElement);
        }

        return;
      }

      if (payloads?.checkout_intent?.object === "ppay_setup_intent") {
        navigate(
          `/v2/stores/${store_slug}/success?ppay_intent_client_secret=${payloads.checkout_intent.client_secret}`,
          {
            replace: true,
          }
        );
        return;
      } else if (payloads?.checkout_intent?.object === "payment_intent") {
        // stripe payment_intent
        elements.submit();
        const cardElement = elements.getElement(CardNumberElement);

        const { error: confirmCardPaymentError } =
          await stripe.confirmCardPayment(
            payloads.checkout_intent.client_secret,
            {
              payment_method: {
                card: cardElement,
              },
            }
          );

        if (confirmCardPaymentError) {
          setCardError(confirmCardPaymentError?.message);
          NotificationManager.error(confirmCardPaymentError?.message);
          return;
        }

        navigate(
          `/v2/stores/${store_slug}/success?payment_intent_client_secret=${payloads.checkout_intent.client_secret}`,
          {
            replace: true,
          }
        );
        return;
      } else if (payloads?.checkout_intent?.object === "setup_intent") {
        // stripe setup intent
        elements.submit();
        const cardElement = elements.getElement(CardNumberElement);

        const { error: confirmCardSetupError } = await stripe.confirmCardSetup(
          payloads.checkout_intent.client_secret,
          {
            payment_method: {
              card: cardElement,
              billing_details: {
                name: obj.name,
                email: obj.email,
              },
            },
          }
        );

        if (confirmCardSetupError) {
          setCardError(confirmCardSetupError?.message);
          NotificationManager.error(confirmCardSetupError?.message);
          return;
        }

        navigate(
          `/v2/stores/${store_slug}/success?payment_intent_client_secret=${payloads.checkout_intent.client_secret}`,
          {
            replace: true,
          }
        );
        return;
      } else {
        NotificationManager.warning("Oops...unhandled payment flow...");
      }
    } catch (error) {
      console.log("💥", error);
      // Possible cases:
      // (1) Coupon permission 8
      // ----> Admin changed not required cc to required cc. At the same time, client side is not showing card elements which required by Stripe.
      // ----> Admin change coupon permission 8 to other than 8. At the same time, client side is not showing card elements which required by Stripe.

      NotificationManager.error(
        "Something went wrong. Please try again later..."
      );
    } finally {
      setIsProcessingPayment(false);
    }
  };

  // Debounce function
  // Find tax
  useEffect(() => {
    const timer = setTimeout(() => {
      callback({
        country_iso2: obj.country_iso2,
        postal_code: obj.postal_code,
        state: obj.state,
      });
    }, 500);

    return () => clearTimeout(timer);
  }, [obj.country_iso2, obj.state, obj.postal_code]);

  // Plan info
  useEffect(() => {
    if (!plan._id) return;

    // Extract only one Stripe gateway for now
    const stripe_gateway = plan?.payment_gateways.find(
      (gateway) => gateway.vendor === "stripe"
    );

    if (stripe_gateway) {
      setIsEnableCheckoutButton(true);
    }
  }, [plan]);

  // Extract email from url
  useEffect(() => {
    const email = new URLSearchParams(window.location.search).get("email");

    if (emailWithConPatternValidator(email)) {
      setIsEmailConPatter(true);
      setIsValid((prevState) => ({
        ...prevState,
        email: false,
      }));

      setIsTouched((prevState) => ({
        ...prevState,
        email: true,
      }));

      setObj((prevState) => ({
        ...prevState,
        email: email,
      }));

      return;
    }

    if (email && !emailValidator(email)) {
      setIsValid((prevState) => ({
        ...prevState,
        email: false,
      }));

      setIsTouched((prevState) => ({
        ...prevState,
        email: true,
      }));

      setObj((prevState) => ({
        ...prevState,
        email: email,
      }));
      return;
    }

    setObj((prevState) => ({
      ...prevState,
      email: email,
    }));

    setIsTouched((prevState) => ({
      ...prevState,
      email: true,
    }));

    setIsValid((prevState) => ({
      ...prevState,
      email: true,
    }));
  }, []);

  // Populate country lists
  useEffect(() => {
    GetCountries().then((results) => {
      const countryLists = results.map((result, index) => ({
        index: index,
        value: result.id,
        label: `${result.emoji} ${result.name}`,
        name: result.name,
        iso2: result.iso2,
        iso3: result.iso3,
        phone_code: `+ ${result.phone_code}`,
      }));

      setCountryOptions(countryLists);
    });
  }, []);

  let formIsValid = false;
  if (
    isValid.name &&
    isValid.email &&
    isValid.country &&
    isValid.state &&
    isValid.phone_number &&
    isValid.authorized
  ) {
    formIsValid = true;
  }

  if (obj.country_iso2 === "US" && !obj.postal_code) {
    formIsValid = false;
  }

  // Pricing Error at grand_total aka final amount
  // disable the Complete purchase button
  if (isNaN(plan?.price_breakdown?.grand_total)) {
    formIsValid = false;
  }

  if (
    !isNaN(plan?.price_breakdown?.grand_total) &&
    plan?.price_breakdown?.grand_total < 0
  ) {
    formIsValid = false;
  }

  return (
    <div>
      {show && (
        <AlertModal show={show} onClose={handleClose}>
          {modalContent}
        </AlertModal>
      )}

      <form>
        {/* Name */}
        <div className='col-12 my-2'>
          <label htmlFor='name' className='form-label'>
            <span className='fw-bold text-uppercase'>Full Name</span>{" "}
            {!isValid.name && isTouched.name && (
              <span className='form-text text-danger'>🚨 Required!</span>
            )}
          </label>
          <input
            type='text'
            className='form-control form-control-lg'
            name='name'
            value={obj.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        {/* Email Address */}
        <div className='col-12 my-2'>
          <label htmlFor='email' className='form-label'>
            <span className='fw-bold text-uppercase'>Email Address</span>{" "}
            {isEmailConPatter && !isValid.email && isTouched.email && (
              <span className='form-text text-danger'>
                🚨 Do you actually mean .com?
              </span>
            )}
            {!isEmailConPatter && !isValid.email && isTouched.email && (
              <span className='form-text text-danger'>
                🚨 A valid email address is required!
              </span>
            )}
          </label>
          <input
            type='text'
            className='form-control form-control-lg'
            name='email'
            value={obj.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <div className='form-text'>
            Note: If you have an existing Piranha Profits’ account, please enter
            the same email address.
          </div>
        </div>

        {/* Country / Region */}
        <div className='col-md-12 mb-3'>
          <label
            htmlFor='country'
            className='form-label fw-bold text-uppercase'
          >
            Country / Region
          </label>

          <Select
            options={countryOptions}
            placeholder='Select your country / region'
            name='country'
            onChange={(selectedOption) =>
              handleSelectChange(selectedOption, "country")
            }
          />
        </div>

        {/* State / County */}
        {obj.country_iso2 !== "US" && stateOptions.length > 0 && (
          <div className='col-md-12 mb-3'>
            <label
              htmlFor='state'
              className='form-label fw-bold text-uppercase'
            >
              State / County
            </label>

            <Select
              options={stateOptions}
              placeholder='Select your state / county'
              name='state'
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, "state")
              }
              ref={stateRef}
            />
          </div>
        )}

        {obj.country_iso2 === "US" && stateOptions.length > 0 && (
          <div className='row'>
            <div className='col-md-6 mb-3'>
              <label
                htmlFor='state'
                className='form-label fw-bold text-uppercase'
              >
                State / County
              </label>

              <Select
                options={stateOptions}
                placeholder='Select your state / county'
                name='state'
                onChange={(selectedOption) =>
                  handleSelectChange(selectedOption, "state")
                }
                ref={stateRef}
              />
            </div>

            {/* Postal Code */}
            <div className='col-md-6 mb-3'>
              <label htmlFor='postal_code' className='form-label'>
                <span className='fw-bold text-uppercase'>Postal Code</span>{" "}
                {!isValid.postal_code && isTouched.postal_code && (
                  <span className='form-text text-danger'>🚨 Required!</span>
                )}
              </label>

              <div className='input-group'>
                <NumericFormat
                  displayType='input'
                  className='form-control form-control-lg'
                  name='postal_code'
                  decimalScale={0}
                  valueIsNumericString={true}
                  value={obj.postal_code}
                  onValueChange={(values) =>
                    handleNumericChange(values, "postal_code")
                  }
                  onBlur={() => handleNumericBlur("postal_code")}
                  disabled={!isValid.country}
                />
              </div>
            </div>
          </div>
        )}

        {/* Phone */}
        <div className='col-md-12'>
          {/* Phone Code */}
          <div className='row'>
            <div className='col-md-4 mb-3'>
              <label
                htmlFor='phone_code'
                className='form-label fw-bold text-uppercase'
              >
                Country Code
              </label>

              <p className='form-control form-control-lg bg-light'>
                {obj.phone_code}
              </p>
            </div>

            {/* Contact Number */}
            <div className='col-md-8 mb-3'>
              <label htmlFor='phone_number' className='form-label'>
                <span className='fw-bold text-uppercase'>Contact Number</span>{" "}
                {!isValid.phone_number && isTouched.phone_number && (
                  <span className='form-text text-danger'>🚨 Required!</span>
                )}
              </label>

              <div className='input-group'>
                <NumericFormat
                  displayType='input'
                  className='form-control form-control-lg'
                  name='phone_number'
                  decimalScale={0}
                  valueIsNumericString={true}
                  value={obj.phone_number}
                  onValueChange={(values) =>
                    handleNumericChange(values, "phone_number")
                  }
                  onBlur={() => handleNumericBlur("phone_number")}
                  disabled={!isValid.country}
                  maxLength={max_phone_length}
                />
              </div>

              <div className='form-text'>
                Note: Please fill in your contact number without country code.
              </div>
            </div>
          </div>
        </div>

        {/* Card Elements: Card Number, Expiry Date, Security Code */}
        {/* No coupon */}
        {!plan?.coupon && (
          <div className='col-12 text-center'>
            <div className='col-12 text-start fw-bold'>
              <p className='m-0 text-uppercase'>Card Number</p>
              <div className='input-group p-3 mb-3 border bg-white rounded-3 d-flex align-items-center'>
                <span className='input-group-text border-0 bg-white'>
                  <CreditCard2Front color='#424770' />
                </span>
                <div className='flex-grow-1 align-items-center'>
                  <CardNumberElement options={cardStyle} />
                </div>
              </div>
            </div>

            <div className='col-12'>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12 text-start fw-bold'>
                  <p className='m-0 text-uppercase'>Expiry Date</p>
                  <div className='input-group p-3 mb-3 border bg-white rounded-3 d-flex align-items-center'>
                    <span className='input-group-text border-0 bg-white'>
                      <Calendar3 color='#424770' />
                    </span>
                    <div className='flex-grow-1 align-items-center'>
                      <CardExpiryElement options={cardStyle} />
                    </div>
                  </div>
                </div>

                <div className='col-lg-6 col-md-6 col-sm-12 text-start fw-bold'>
                  <p className='m-0 text-uppercase'>Security Code</p>
                  <div className='input-group p-3 mb-3 border bg-white rounded-3 d-flex align-items-center'>
                    <span className='input-group-text border-0 bg-white'>
                      <CreditCard2Back color='#424770' />
                    </span>
                    <div className='flex-grow-1 align-items-center'>
                      <CardCvcElement options={cardStyle} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {cardError && (
              <p className='text-danger fw-bold text-start'>
                Card Error: {cardError}
              </p>
            )}
          </div>
        )}

        {/* Has coupon but not free trial */}
        {plan?.coupon && plan?.coupon?.permission !== 8 && (
          <div className='col-12 text-center'>
            <div className='col-12 text-start fw-bold'>
              <p className='m-0 text-uppercase'>Card Number</p>
              <div className='input-group p-3 mb-3 border bg-white rounded-3 d-flex align-items-center'>
                <span className='input-group-text border-0 bg-white'>
                  <CreditCard2Front color='#424770' />
                </span>
                <div className='flex-grow-1 align-items-center'>
                  <CardNumberElement options={cardStyle} />
                </div>
              </div>
            </div>

            <div className='col-12'>
              <div className='row'>
                <div className='col-lg-6 col-md-6 col-sm-12 text-start fw-bold'>
                  <p className='m-0 text-uppercase'>Expiry Date</p>
                  <div className='input-group p-3 mb-3 border bg-white rounded-3 d-flex align-items-center'>
                    <span className='input-group-text border-0 bg-white'>
                      <Calendar3 color='#424770' />
                    </span>
                    <div className='flex-grow-1 align-items-center'>
                      <CardExpiryElement options={cardStyle} />
                    </div>
                  </div>
                </div>

                <div className='col-lg-6 col-md-6 col-sm-12 text-start fw-bold'>
                  <p className='m-0 text-uppercase'>Security Code</p>
                  <div className='input-group p-3 mb-3 border bg-white rounded-3 d-flex align-items-center'>
                    <span className='input-group-text border-0 bg-white'>
                      <CreditCard2Back color='#424770' />
                    </span>
                    <div className='flex-grow-1 align-items-center'>
                      <CardCvcElement options={cardStyle} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {cardError && (
              <p className='text-danger fw-bold text-start'>
                Card Error: {cardError}
              </p>
            )}
          </div>
        )}

        {/* Has coupon. Free trial. And card is required. */}
        {plan?.coupon &&
          plan?.coupon?.permission === 8 &&
          plan?.coupon?.free_trial_is_cc_required && (
            <div className='col-12 text-center'>
              <div className='col-12 text-start fw-bold'>
                <p className='m-0 text-uppercase'>Card Number</p>
                <div className='input-group p-3 mb-3 border bg-white rounded-3 d-flex align-items-center'>
                  <span className='input-group-text border-0 bg-white'>
                    <CreditCard2Front color='#424770' />
                  </span>
                  <div className='flex-grow-1 align-items-center'>
                    <CardNumberElement options={cardStyle} />
                  </div>
                </div>
              </div>

              <div className='col-12'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6 col-sm-12 text-start fw-bold'>
                    <p className='m-0 text-uppercase'>Expiry Date</p>
                    <div className='input-group p-3 mb-3 border bg-white rounded-3 d-flex align-items-center'>
                      <span className='input-group-text border-0 bg-white'>
                        <Calendar3 color='#424770' />
                      </span>
                      <div className='flex-grow-1 align-items-center'>
                        <CardExpiryElement options={cardStyle} />
                      </div>
                    </div>
                  </div>

                  <div className='col-lg-6 col-md-6 col-sm-12 text-start fw-bold'>
                    <p className='m-0 text-uppercase'>Security Code</p>
                    <div className='input-group p-3 mb-3 border bg-white rounded-3 d-flex align-items-center'>
                      <span className='input-group-text border-0 bg-white'>
                        <CreditCard2Back color='#424770' />
                      </span>
                      <div className='flex-grow-1 align-items-center'>
                        <CardCvcElement options={cardStyle} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {cardError && (
                <p className='text-danger fw-bold text-start'>
                  Card Error: {cardError}
                </p>
              )}
            </div>
          )}

        {/* Agreement box */}
        <div className='col-md-12 mb-3 form-check'>
          <p>By ticking the box below, you:</p>

          <div>
            <ol>
              <li>
                Agree to our{" "}
                <a
                  href='https://www.piranhaprofits.com/terms-of-service'
                  target='_blank'
                >
                  Terms of Service
                </a>{" "}
                &{" "}
                <a
                  href='https://www.piranhaprofits.com/privacy-policy'
                  target='_blank'
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                ⁠Have read and understood our{" "}
                <a
                  href='https://www.piranhaprofits.com/disclaimer'
                  target='_blank'
                >
                  Disclaimer and Risk Disclosure
                </a>
              </li>
            </ol>
          </div>

          <p className='text-muted fst-italic'>
            Note: If you bought a subscription, Piranha Pte Ltd will
            automatically renew it and charge your payment method until you
            cancel.
          </p>

          <div className='px-3'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              id='authorizedCheck'
              required
              onClick={handleCheck}
              style={{ transform: "scale(2)" }}
            />
            <label className='form-check-label px-2' htmlFor='authorizedCheck'>
              I understand and agree to the above.
            </label>
          </div>
        </div>

        {/* Button */}
        {isEnableCheckoutButton ? (
          <>
            <div className='col-md-12 mb-3 d-grid gap-2'>
              <button
                className='btn btn-lg btn-block fw-bold rounded-pill text-white'
                style={{ backgroundColor: "#3CAD66" }}
                id='submit'
                disabled={isProcessingPayment || !formIsValid}
                onClick={paymentHandler}
              >
                {isProcessingPayment ? (
                  <>
                    <span
                      className='spinner-border spinner-border-sm'
                      aria-hidden='true'
                    ></span>
                    <span role='status'>Processing...</span>
                  </>
                ) : (
                  <span id='button-text'>Complete purchase</span>
                )}
              </button>
            </div>

            <div
              className='col-12 my-2 text-center m-2 fw-normal'
              style={{ fontSize: "12px" }}
            >
              <EncryptionIcon />
              Encrypted & secure payments
            </div>
          </>
        ) : (
          <>
            <div className='col-md-12 mb-3 d-grid gap-2'>
              <button
                className='btn btn-lg btn-block fw-bold rounded-pill text-white'
                // style={{ backgroundColor: "red" }}
                disabled={true}
              >
                No payment gateway found
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default PaymentForm;
