import React, { useEffect, useState } from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { Link, useParams } from "react-router-dom";
import Logo from "../../../../components/version/2/Checkout/Logo";

import { ListGroup } from "react-bootstrap";
import {
  ArrowClockwise,
  Calendar3,
  CaretDownFill,
  CaretUpFill,
  Coin,
  XLg,
  CheckLg,
} from "react-bootstrap-icons";
import { formatPrice } from "../../../../utils/formatValues";

import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

// import PaymentForm from "../../components/Checkout/PaymentForm";
// import { extractUTM } from "../../utils/extractURLParams";
import BasicSpinner from "../../../../components/UI/Spinner";
import { removeSpecialCharacters } from "../../../../utils/removeSpecialCharacters";

import "./custom.css";
import PaymentForm from "../../../../components/version/2/Checkout/PaymentForm";
import { NotificationManager } from "react-notifications";

const Checkout = () => {
  const { store, price_slug } = useParams();
  if (!store || !price_slug) window.location.replace("/");

  const [isLoading, setIsLoading] = useState(true);
  const [planOverview, setPlanOverview] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);

  const [coupon, setCoupon] = useState(null);
  const [isAppliedCoupon, setIsAppliedCoupon] = useState(false);
  const [isFetchingCoupon, setIsFetchingCoupon] = useState(false);

  const [addressDetails, setAddressDetails] = useState({
    country_iso2: "",
    postal_code: "",
    state: "",
  });

  const getPlanOverview = async (store_slug, price_slug, body = {}) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v2/stores/${store_slug}/plans/${price_slug}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      }
    );

    const data = await response.json();

    const { errors, payloads } = data;

    if (errors) {
      const price_plan_out_of_stock = errors.find(
        (error) => error.field === "price_plan.out_of_stock"
      );

      if (price_plan_out_of_stock) {
        NotificationManager.error(price_plan_out_of_stock.message);
      }

      return;
    }

    if (!errors) {
      setPlanOverview(payloads);
    }

    return data;
  };

  // Mostly use to find tax amount
  const callbackFromPaymentForm = async (options) => {
    setAddressDetails({
      country_iso2: options.country_iso2,
      state: options.state,
      postal_code: options.postal_code,
    });

    let reqBody = {};

    if (coupon) {
      reqBody = {
        ...reqBody,
        coupon: removeSpecialCharacters(coupon),
      };
    }

    reqBody = { ...reqBody, ...options };

    const { errors } = await getPlanOverview(store, price_slug, reqBody);

    if (errors) {
      const postal_code_error = errors.find(
        (error) => error.field === "postal_code"
      );

      if (postal_code_error) {
        NotificationManager.error(postal_code_error.message);
      }

      return;
    }
  };

  const applyCouponCodeHandler = async (e) => {
    e.preventDefault();

    let reqBody = {};
    if (coupon) {
      setCoupon(removeSpecialCharacters(coupon));

      reqBody = {
        ...addressDetails,
        coupon: removeSpecialCharacters(coupon),
      };

      setIsAppliedCoupon(true);
    }

    try {
      setIsFetchingCoupon(true);

      const { payloads } = await getPlanOverview(store, price_slug, reqBody);
      if (payloads) {
        const bundled_items = payloads.bundles || [];
        const has_coupon = bundled_items.filter((item) =>
          Boolean(item?.coupon?.coupon_id)
        );

        if (!payloads.coupon && !has_coupon.length && Boolean(coupon)) {
          setIsAppliedCoupon(false);
          NotificationManager.error("Invalid coupon");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchingCoupon(false);
    }
  };

  const removeCouponCodeHandler = async (e) => {
    e.preventDefault();
    setCoupon(null);
    setIsAppliedCoupon(false);

    let reqBody;

    reqBody = {
      ...addressDetails,
    };

    await getPlanOverview(store, price_slug, reqBody);
  };

  const couponCodeChangeHandler = (e) => {
    e.preventDefault();
    setIsAppliedCoupon(false);
    setCoupon(e.target.value);
  };

  // ✅ Get price plan info
  // load one time
  useEffect(() => {
    // get params on url
    // example, coupon_code, utm_xxx and so on...
    const searchParams = new URLSearchParams(document.location.search);
    const coupon_code = searchParams.get("coupon_code");

    let reqBody = {};
    if (coupon_code) {
      setCoupon(removeSpecialCharacters(coupon_code));

      reqBody = {
        coupon: removeSpecialCharacters(coupon_code),
      };

      setIsAppliedCoupon(true);
    }

    const getPlan = async () => {
      setIsLoading(true);

      try {
        const data = await getPlanOverview(store, price_slug, reqBody);

        const { errors, payloads } = data;

        if (errors) {
          NotificationManager.error("Error to retrieve item");
          return;
        }

        // Load payment gateways
        // Notes:
        // - Extract only one Stripe gateway for now
        // - Although it still loads technicall, we put it here so that Stripe card elements load only one time.
        // - Why? Because we supposed to load Stripe for only one time based on Stripe documentation.
        const { payment_gateways = [] } = payloads;
        if (payment_gateways.length) {
          const stripe_gateway = payloads?.payment_gateways.find(
            (gateway) => gateway.vendor === "stripe"
          );

          if (stripe_gateway) {
            setStripePromise(loadStripe(stripe_gateway.stripe_publishable_key));
          }
        }

        const {
          fb_pixel = null,
          fb_pixel_test_code = null,
          google_analytics_id = null,
        } = payloads.trackings;
        let metadata = {};

        if (Boolean(fb_pixel_test_code)) {
          metadata.test_event_code = fb_pixel_test_code;
        }

        if (Boolean(fb_pixel)) {
          ReactPixel.init(fb_pixel);
          ReactPixel.track("InitiateCheckout", metadata);
        }

        if (Boolean(google_analytics_id)) {
          ReactGA.initialize(google_analytics_id);

          ReactGA.event({
            category: "Ecommerce",
            action: "Begin Checkout",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getPlan();
  }, [store, price_slug]);

  return (
    <div className='container'>
      <div className='row mt-5'>
        <Link to={`/stores/${store}`} className='text-center'>
          <Logo />
        </Link>
      </div>

      <div className='row g-5 justify-content-center m-0 p-0'>
        <div className='col-lg-5 col-md-12 order-md-last m-4 p-0'>
          {/* 📝 Order summary */}
          <div className='border bg-white p-3 rounded-4'>
            <h4 className='mb-3'>Order summary</h4>
            <hr />

            {isLoading && (
              <>
                <div className='spinner-grow mx-1' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
                <div className='spinner-grow mx-1' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
                <div className='spinner-grow mx-1' role='status'>
                  <span className='visually-hidden'>Loading...</span>
                </div>
              </>
            )}

            {!planOverview && !isLoading && (
              <p className='text-danger fw-bold'>Invalid product</p>
            )}

            {planOverview && (
              <ListGroup.Item key={planOverview._id}>
                {planOverview?.thumbnail && (
                  <img
                    src={`${process.env.REACT_APP_IMAGES_ENDPOINT}/${planOverview?.thumbnail}`}
                    className='mb-3'
                    width={"100%"}
                    alt='piranha profits thumbnail'
                  />
                )}

                <h6 className='fw-bold'>
                  {"1"}. {planOverview?.name}
                </h6>

                {/* Display selling price */}
                <p className='text-body-secondary'>
                  <Coin /> Usual Price:{" "}
                  {String(planOverview?.currency).toUpperCase()}{" "}
                  {formatPrice(planOverview?.selling_price)}
                </p>

                {/* Condition: Applied coupon code */}
                {/* Display "Discount applied: $ xxx.xx" */}
                {planOverview?.price_breakdown?.discount_amount > 0 && (
                  <p className='fw-bolder text-danger'>
                    <CaretDownFill /> Discount applied:{" "}
                    {String(planOverview?.currency).toUpperCase()}{" "}
                    {formatPrice(
                      planOverview?.price_breakdown?.discount_amount || 0
                    )}
                  </p>
                )}

                {/* 📦📦📦📦📦 UI for bundle items */}
                {planOverview?.is_bundled && planOverview.bundles.length && (
                  <>
                    {planOverview.bundles.map((child, index) => {
                      return (
                        <ListGroup.Item
                          key={`${child._id}-${index}`}
                          className='border rounded-4 p-3 my-3 bg-light'
                        >
                          <h6>
                            Bundle Item {index + 1}: {child?.name}
                          </h6>

                          <hr />

                          {/* Display selling price */}
                          <p className='text-body-secondary'>
                            <Coin /> Usual Price:{" "}
                            {String(child?.currency).toUpperCase()}{" "}
                            {formatPrice(child?.selling_price)}
                          </p>

                          {/* child is a one-time payment */}
                          {!child.recurring && (
                            <p className='text-body-secondary'>
                              <Calendar3 /> One-time payment
                            </p>
                          )}

                          {/* child is a recurring plan */}
                          {child.recurring && (
                            <>
                              {/* Display "XX-day/month/year subscription" */}
                              <p className='text-body-secondary'>
                                <Calendar3 /> {child?.interval_count}-
                                {child?.interval} subscription
                              </p>

                              {child.coupon &&
                              child?.coupon?.extension_interval_count ? (
                                <>
                                  <p className='fw-bolder green-text'>
                                    <CaretUpFill /> Bonus: Free{" "}
                                    {child?.coupon?.extension_interval_count}-
                                    {child?.coupon?.extension_interval}{" "}
                                    subscription (for 1st year only)
                                  </p>
                                </>
                              ) : (
                                ""
                              )}

                              {/* Auto renewal pricing */}
                              {child?.next_price_breakdown?.grand_total > 0 && (
                                <p className='fw-bolder blue-text'>
                                  <ArrowClockwise /> Auto-renewal at{" "}
                                  {
                                    <span>
                                      {String(child.currency).toUpperCase()}{" "}
                                      {formatPrice(
                                        child?.next_price_breakdown?.grand_total
                                      )}{" "}
                                      {child?.interval === "day"
                                        ? "dai"
                                        : child?.interval}
                                      ly until cancelled. **
                                    </span>
                                  }
                                </p>
                              )}

                              {/* T&C of coupon */}
                              {child.coupon && (
                                <p className='text-muted fst-italic small'>
                                  * Terms and conditions apply for this coupon.
                                </p>
                              )}
                            </>
                          )}
                        </ListGroup.Item>
                      );
                    })}
                  </>
                )}

                {/* 📦 UI for a recurring plan */}
                {planOverview.recurring && (
                  <>
                    {/* Display "XX-day/month/year subscription" */}
                    <p className='text-body-secondary'>
                      <Calendar3 /> {planOverview?.interval_count}-
                      {planOverview?.interval} subscription
                    </p>

                    {planOverview.coupon && (
                      <>
                        {planOverview.coupon.permission === 8 && (
                          <>
                            <p className='fw-bolder green-text'>
                              <CaretUpFill /> Bonus: Free{" "}
                              {planOverview?.coupon?.free_trial_interval_count}-
                              {planOverview?.coupon?.free_trial_interval}{" "}
                              subscription (for 1st year only)
                            </p>

                            {/* if required payment method, we can proceed with setup intent */}
                            {/* Meaning, capture payment method and perform auto-renewal later */}
                            {/* So, showing auto renewal is a valid action */}
                            {planOverview?.coupon.free_trial_is_cc_required && (
                              <p className='fw-bolder blue-text'>
                                <ArrowClockwise /> Auto-renewal at{" "}
                                {
                                  <span>
                                    {String(
                                      planOverview.currency
                                    ).toUpperCase()}{" "}
                                    {formatPrice(
                                      planOverview?.next_price_breakdown
                                        ?.grand_total
                                    )}{" "}
                                    {planOverview?.interval === "day"
                                      ? "dai"
                                      : planOverview?.interval}
                                    ly until cancelled. **
                                  </span>
                                }
                              </p>
                            )}
                          </>
                        )}

                        {[1, 2, 3, 4, 5, 6, 7, 9].includes(
                          planOverview.coupon.permission
                        ) &&
                        planOverview?.coupon?.extension_interval_count > 0 ? (
                          <>
                            <p className='fw-bolder green-text'>
                              <CaretUpFill /> Bonus: Free{" "}
                              {planOverview?.coupon?.extension_interval_count}-
                              {planOverview?.coupon?.extension_interval}{" "}
                              subscription (for 1st year only)
                            </p>

                            <p className='fw-bolder blue-text'>
                              <ArrowClockwise /> Auto-renewal at{" "}
                              {
                                <span>
                                  {String(planOverview.currency).toUpperCase()}{" "}
                                  {formatPrice(
                                    planOverview?.next_price_breakdown
                                      ?.grand_total
                                  )}{" "}
                                  {planOverview?.interval === "day"
                                    ? "dai"
                                    : planOverview?.interval}
                                  ly until cancelled. **
                                </span>
                              }
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    )}

                    {/* Auto renewal pricing */}
                    {!planOverview.coupon &&
                      planOverview?.next_price_breakdown?.grand_total > 0 && (
                        <p className='fw-bolder blue-text'>
                          <ArrowClockwise /> Auto-renewal at{" "}
                          {
                            <span>
                              {String(planOverview.currency).toUpperCase()}{" "}
                              {formatPrice(
                                planOverview?.next_price_breakdown?.grand_total
                              )}{" "}
                              {planOverview?.interval === "day"
                                ? "dai"
                                : planOverview?.interval}
                              ly until cancelled. **
                            </span>
                          }
                        </p>
                      )}
                  </>
                )}

                {/* T&C of coupon */}
                {planOverview.coupon &&
                  !planOverview.is_bundled &&
                  !planOverview.bundles.length && (
                    <p className='text-muted fst-italic small'>
                      * Terms and conditions apply for this coupon.
                    </p>
                  )}

                <p className='text-muted fst-italic small'>
                  ** All prices are subject to the applicable taxes.
                </p>
              </ListGroup.Item>
            )}
          </div>

          {/* 💰 Pricing summary */}
          <div className='border bg-white p-3 rounded-4 my-3'>
            <h4 className='mb-3'>Pricing summary</h4>
            <hr />

            {planOverview && (
              <div className='me-auto'>
                <table className='table table-responsive'>
                  <tbody>
                    <tr>
                      <td>Currency</td>
                      <td>:</td>
                      <td>{String(planOverview?.currency).toUpperCase()}</td>
                    </tr>
                    <tr>
                      <td>Amount</td>
                      <td>:</td>
                      <td>
                        $ {formatPrice(planOverview?.price_breakdown?.amount)}
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "1.5px solid grey" }}>
                      <td>Discount</td>
                      <td>:</td>
                      <td>
                        {planOverview?.price_breakdown?.discount_amount > 0
                          ? "-"
                          : ""}{" "}
                        ${" "}
                        {formatPrice(
                          planOverview?.price_breakdown?.discount_amount
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Sub total</td>
                      <td>:</td>
                      <td>
                        ${" "}
                        {formatPrice(planOverview?.price_breakdown?.sub_total)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Tax{" "}
                        {planOverview?.tax_breakdown
                          ? `(${planOverview?.tax_breakdown?.tax_rate_details?.percentage_decimal}%)`
                          : ""}
                      </td>
                      <td>:</td>
                      <td>
                        ${" "}
                        {formatPrice(planOverview?.price_breakdown?.tax_amount)}
                      </td>
                    </tr>
                    <tr className='table-secondary fw-bold'>
                      <td>Final amount</td>
                      <td>:</td>
                      <td>
                        ≈ ${" "}
                        {formatPrice(
                          planOverview?.price_breakdown?.grand_total
                        )}{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>

                {planOverview?.price_breakdown?.grand_total < 0 && (
                  <p className='text-danger text-center'>
                    Oops! Pricing error...
                  </p>
                )}
              </div>
            )}
          </div>

          {/* Coupon Code */}
          <div className='border bg-white p-3 rounded-4 my-3'>
            <p>Have a coupon code?</p>

            <div className='input-group'>
              <input
                type='text'
                className='form-control'
                placeholder='Key in the coupon code'
                value={coupon ? coupon : ""}
                onChange={couponCodeChangeHandler}
                disabled={isAppliedCoupon}
              />
              {!isAppliedCoupon && (
                <button
                  type='submit'
                  className='btn btn-secondary'
                  disabled={isFetchingCoupon}
                  onClick={applyCouponCodeHandler}
                >
                  {isFetchingCoupon ? (
                    <BasicSpinner variant='white' small />
                  ) : (
                    <CheckLg />
                  )}
                </button>
              )}

              {isAppliedCoupon && (
                <button
                  type='submit'
                  className='btn btn-secondary'
                  onClick={removeCouponCodeHandler}
                >
                  <XLg />
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Payment Form */}
        <div className='col-lg-6 col-md-12 me-lg-1 m-4 p-0'>
          <div className='shadow border bg-white p-3 rounded-4'>
            <h4 className='mb-3'>Payment information</h4>
            <hr />

            {planOverview && (
              <Elements
                options={{
                  appearance: {
                    theme: "stripe",
                    rules: {
                      ".Label": {
                        textTransform: "uppercase",
                        fontWeight: "bold",
                      },
                    },
                  },
                }}
                stripe={stripePromise}
              >
                <PaymentForm
                  plan={planOverview}
                  store_slug={store}
                  price_slug={price_slug}
                  coupon={coupon}
                  callback={callbackFromPaymentForm}
                />
              </Elements>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
