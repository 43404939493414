import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";

// ##### Public ##### \\
import Homepage from "./pages/Public/Homepage";
import RestrictedRoute from "./components/Protected/RestrictedRoute";
import Storefront from "./pages/Storefront/Storefront";
// import Checkout from "./pages/Checkout/Checkout";
import SingleCheckout from "./pages/Checkout/SingleCheckout";
// import PromotionalOffers from "./pages/Checkout/Offers";
import Error404 from "./pages/Error/Error404";
import MaintenancePage from "./pages/Maintenance/Maintenance";

import Checkout_v2 from "./pages/version/2/Checkout/Checkout";

const PaymentSuccess = lazy(() => import("./pages/Checkout/PaymentSuccess"));
const Receipt = lazy(() => import("./pages/Checkout/Receipt"));

// ##### Logged in ##### \\
const Login = lazy(() => import("./pages/Public/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));

// Stores
const AddStoreAccount = lazy(() =>
  import("./pages/StoreAccounts/AddStoreAccount")
);
const EditStoreAccount = lazy(() =>
  import("./pages/StoreAccounts/EditStoreAccount")
);
const StoreAccounts = lazy(() => import("./pages/StoreAccounts/StoreAccounts"));

// Admins
const Admins = lazy(() => import("./pages/Admins/Admins"));
const AddAdmin = lazy(() => import("./pages/Admins/AddAdmin"));
const EditAdmin = lazy(() => import("./pages/Admins/EditAdmin"));

// My Profile
const MyAccount = lazy(() => import("./pages/me/MyAccount"));

// Products
const Products = lazy(() => import("./pages/Products/Products"));
const AddProduct = lazy(() => import("./pages/Products/AddProduct"));
const Product = lazy(() => import("./pages/Product/Product"));

// Price plans of the team
const Prices = lazy(() => import("./pages/Prices/Prices"));

// Price plans of product
const Price = lazy(() => import("./pages/Product/Price"));
const AddPrice = lazy(() => import("./pages/Product/AddPrice"));
const EditPrice = lazy(() => import("./pages/Product/EditPrice"));

// Coupons of the team
const AllCoupons = lazy(() => import("./pages/Coupons/Coupons"));

// Coupons of the price plans
const Coupons = lazy(() => import("./pages/Coupon/Coupons"));
const AddCoupon = lazy(() => import("./pages/Coupon/AddCoupon"));
const Coupon = lazy(() => import("./pages/Coupon/Coupon"));

// // All promos of the team
// const AllPromos = lazy(() => import("./pages/Promos/Promos"));

// // Promos of the price plans
// const Promos = lazy(() => import("./pages/Promo/Promos"));
// const AddPromo = lazy(() => import("./pages/Promo/AddPromo"));
// const EditPromo = lazy(() => import("./pages/Promo/EditPromo"));

// // Discount stacks
// const DiscountStacks = lazy(() =>
//   import("./pages/DiscountStacks/DiscountStacks")
// );

// Discord
const DiscordSettings = lazy(() =>
  import("./pages/DiscordRoles/DiscordRolesSetting")
);
const DiscordRoles = lazy(() => import("./pages/DiscordRoles/DiscordRoles"));
const DiscordRole = lazy(() => import("./pages/DiscordRole/DiscordRole"));

// Discord
const Imports = lazy(() => import("./pages/Imports/Imports"));

// Webhooks
// const Webhooks = lazy(() => import("./pages/Webhooks/Webhooks"));
// const Webhook = lazy(() => import("./pages/Webhook/Webhook"));
// const AddWebhook = lazy(() => import("./pages/Webhook/AddWebhook"));

// Students
const Students = lazy(() => import("./pages/Students/Students"));
const AddStudent = lazy(() => import("./pages/Students/AddStudent"));
const Student = lazy(() => import("./pages/Student/Student"));
const StudentOrders = lazy(() => import("./pages/Student/StudentOrders"));

// Subscriptions of students
const Subscriptions = lazy(() => import("./pages/Student/Subscriptions"));

// Orders of students
const Orders = lazy(() => import("./pages/Orders/Orders"));
const AutoRenewalItems = lazy(() =>
  import("./pages/version/2/Items/AutoRenewalItems")
);

// Orders of student
// const OrderInfo = lazy(() => import("./pages/Student/OrderInfo"));
const TransferOrder = lazy(() => import("./pages/Student/TransferOrder"));
const AddOrder = lazy(() => import("./pages/Student/AddOrder"));

// Order items of student
// const ItemInfo = lazy(() => import("./pages/Item/ItemInfo"));
const ChangeOrderItem = lazy(() => import("./pages/Student/ChangeOrderItem"));
// const RefundOrderItem = lazy(() => import("./pages/Student/RefundOrderItem"));
const CancelOrderItem = lazy(() => import("./pages/Student/CancelOrderItem"));

// Reports
// const ProfitabilityReports = lazy(() =>
//   import("./pages/Report/ProfitabilityReports")
// );
// const SalesVolumeReports = lazy(() =>
//   import("./pages/Report/SalesVolumeReports")
// );

// Settings
const Settings = lazy(() => import("./pages/Settings/Settings"));
const Gateways = lazy(() => import("./pages/Settings/Gateways"));
const HubSpotProperties = lazy(() =>
  import("./pages/Settings/HubSpotProperties")
);

const AllDomains = lazy(() => import("./pages/Domains/AllDomains"));
const AddDomain = lazy(() => import("./pages/Domains/AddDomain"));
const EditDomain = lazy(() => import("./pages/Domains/EditDomain"));

// PrivateApps
const PrivateApps = lazy(() => import("./pages/PrivateApps/PrivateApps"));
const EditPrivateApp = lazy(() => import("./pages/PrivateApp/EditPrivateApp"));
const AddPrivateApp = lazy(() => import("./pages/PrivateApp/AddPrivateApp"));

// Automations
const Automation = lazy(() => import("./pages/Automation/Automation"));
const AddAutomation = lazy(() => import("./pages/Automation/AddAutomation"));

const EditAutomation = lazy(() => import("./pages/Automation/EditAutomation"));

// ##### Version 2 ##### //
const PaymentSuccess_v2 = lazy(() =>
  import("./pages/version/2/PaymentStatus/PaymentSuccess")
);

// Order and order items
const OrderInfo_v2 = lazy(() => import("./pages/version/2/Student/OrderInfo"));
const RefundOrderItem_v2 = lazy(() =>
  import("./pages/version/2/Student/RefundOrderItem")
);
const ItemInfo_v2 = lazy(() => import("./pages/version/2/Item/ItemInfo"));

// Webhooks
const Webhooks_v2 = lazy(() => import("./pages/version/2/Webhooks/Webhooks"));
const EditWebhook_v2 = lazy(() =>
  import("./pages/version/2/Webhook/EditWebhook")
);
const AddWebhook_v2 = lazy(() =>
  import("./pages/version/2/Webhook/AddWebhook")
);

// Reports
const ProfitabilityReports_v2 = lazy(() =>
  import("./pages/version/2/Reports/ProfitabilityReports")
);
const SalesVolumeReports_v2 = lazy(() =>
  import("./pages/version/2/Reports/SalesVolumeReports")
);

function App() {
  const isUnderMaintenance = process.env.REACT_APP_MAINTENANCE_MODE === "true";

  return (
    <Suspense fallback={<div></div>}>
      <Routes>
        {isUnderMaintenance ? (
          <Route path='*' element={<MaintenancePage />} />
        ) : (
          <>
            <Route path='/' element={<Homepage />} />

            <Route path='/stores/:store'>
              <Route index element={<Storefront />} />
              {/* <Route path='checkout' element={<Checkout />} /> */}
              <Route path='plans/:price_slug' element={<SingleCheckout />} />

              {/* <Route path='offers' element={<PromotionalOffers />} /> */}
              <Route path='success' element={<PaymentSuccess />} />
              <Route path='receipt' element={<Receipt />} />
            </Route>

            {/* Remove later */}
            {/* Version 2 */}
            <Route path='/v2/stores/:store'>
              <Route path='success' element={<PaymentSuccess_v2 />} />

              <Route path='plans/:price_slug' element={<Checkout_v2 />} />
            </Route>

            <Route path='/login' element={<Login />} />

            {/* Owners && Admins only */}
            <Route element={<RestrictedRoute />}>
              <Route path='/dashboards' element={<Dashboard />} />

              <Route path='/store-accounts'>
                <Route index path='' element={<StoreAccounts />} />
                <Route path='new' element={<AddStoreAccount />} />
                <Route path=':store_id' element={<EditStoreAccount />} />
              </Route>

              <Route path='/admins'>
                <Route index path='' element={<Admins />} />
                <Route path='new' element={<AddAdmin />} />
                <Route path=':admin_id' element={<EditAdmin />} />
              </Route>

              <Route path='/my-account' element={<MyAccount />} />

              {/* Teams */}
              <Route path='/teams/:team_id'>
                {/* Students */}
                <Route path='students' element={<Students />} />
                <Route path='students/new' element={<AddStudent />} />
                <Route path='students/:student_id' element={<Student />} />

                {/* Orders of student */}
                {/* All orders of the students */}
                <Route
                  path='students/:student_id/orders'
                  element={<StudentOrders />}
                />
                <Route
                  path='students/:student_id/orders/new'
                  element={<AddOrder />}
                />
                {/* <Route
                  path='students/:student_id/orders/:order_id'
                  element={<OrderInfo />}
                /> */}
                <Route
                  path='students/:student_id/orders/:order_id'
                  element={<OrderInfo_v2 />}
                />

                <Route
                  path='students/:student_id/orders/:order_id/transfer'
                  element={<TransferOrder />}
                />

                <Route
                  path='students/:student_id/subscriptions'
                  element={<Subscriptions />}
                />

                {/* Order items of student */}
                {/* <Route
                  path='students/:student_id/orders/:order_id/items/:item_id'
                  element={<ItemInfo />}
                /> */}
                <Route
                  path='students/:student_id/orders/:order_id/items/:item_id'
                  element={<ItemInfo_v2 />}
                />

                <Route
                  path='students/:student_id/orders/:order_id/items/:item_id/change'
                  element={<ChangeOrderItem />}
                />
                {/* <Route
                  path='students/:student_id/orders/:order_id/items/:item_id/refund'
                  element={<RefundOrderItem />}
                /> */}
                <Route
                  path='students/:student_id/orders/:order_id/items/:item_id/refund'
                  element={<RefundOrderItem_v2 />}
                />

                <Route
                  path='students/:student_id/orders/:order_id/items/:item_id/cancel'
                  element={<CancelOrderItem />}
                />

                {/* All orders of the team */}
                <Route path='orders' element={<Orders />} />
                <Route
                  path='auto-renewal-lists'
                  element={<AutoRenewalItems />}
                />

                {/* All price plans of the team */}
                <Route path='prices' element={<Prices />} />

                {/* All coupons of the team */}
                <Route path='coupons' element={<AllCoupons />} />

                {/* All promotions of the team */}
                {/* <Route path='promos' element={<AllPromos />} /> */}

                {/* All coupons of the team */}
                {/* <Route path='discount-stacks' element={<DiscountStacks />} /> */}

                {/* Products */}
                <Route path='products' element={<Products />} />
                <Route path='products/new' element={<AddProduct />} />
                <Route path='products/:product_id' element={<Product />} />

                {/* Price plans of products */}
                <Route path='products/:product_id/prices' element={<Price />} />
                <Route
                  path='products/:product_id/prices/new'
                  element={<AddPrice />}
                />
                <Route
                  path='products/:product_id/prices/:price_id'
                  element={<EditPrice />}
                />

                {/* Coupon */}
                {/* All coupons of the price plans */}
                <Route
                  path='products/:product_id/prices/:price_id/coupons'
                  element={<Coupons />}
                />
                <Route
                  path='products/:product_id/prices/:price_id/coupons/new'
                  element={<AddCoupon />}
                />
                <Route
                  path='products/:product_id/prices/:price_id/coupons/:coupon_id'
                  element={<Coupon />}
                />

                {/* Promos */}
                {/* All promos of the price plans */}
                {/* <Route
                  path='products/:product_id/prices/:price_id/promos'
                  element={<Promos />}
                />
                <Route
                  path='products/:product_id/prices/:price_id/promos/new'
                  element={<AddPromo />}
                />
                <Route
                  path='products/:product_id/prices/:price_id/promos/:promo_id'
                  element={<EditPromo />}
                /> */}

                {/* Reports */}
                {/* <Route path='reports' element={<ProfitabilityReports />} />
                <Route path='reports/plans' element={<SalesVolumeReports />} /> */}

                {/* Reports */}
                <Route path='reports' element={<ProfitabilityReports_v2 />} />
                <Route
                  path='reports/sales-volume'
                  element={<SalesVolumeReports_v2 />}
                />

                {/* Settings */}
                <Route path='settings' element={<Settings />} />
                <Route path='settings/gateways' element={<Gateways />} />
                <Route path='settings/domains' element={<AllDomains />} />
                <Route path='settings/domains/new' element={<AddDomain />} />
                <Route
                  path='settings/domains/:ppay_domain_id'
                  element={<EditDomain />}
                />
                <Route
                  path='settings/hubspot'
                  element={<HubSpotProperties />}
                />

                {/* Discord Roles */}
                <Route path='discord' element={<DiscordSettings />} />
                <Route path='discord/roles' element={<DiscordRoles />} />
                <Route
                  path='discord/roles/:discord_role_id'
                  element={<DiscordRole />}
                />

                {/* Imports */}
                <Route path='imports' element={<Imports />} />

                {/* Webhooks */}
                {/* <Route path='webhooks' element={<Webhooks />} />
                <Route path='webhooks/:webhook_id' element={<Webhook />} />
                <Route path='webhooks/new' element={<AddWebhook />} /> */}

                {/* Webhooks */}
                <Route path='webhooks' element={<Webhooks_v2 />} />
                <Route
                  path='webhooks/:webhook_id'
                  element={<EditWebhook_v2 />}
                />
                <Route path='webhooks/new' element={<AddWebhook_v2 />} />

                {/* PrivateApps */}
                <Route path='private-apps' element={<PrivateApps />} />
                <Route
                  path='private-apps/:app_id'
                  element={<EditPrivateApp />}
                />
                <Route path='private-apps/new' element={<AddPrivateApp />} />

                {/* Automations */}
                <Route path='automations' element={<Automation />} />
                <Route path='automations/new' element={<AddAutomation />} />
                <Route
                  path='automations/:automation_id'
                  element={<EditAutomation />}
                />
              </Route>

              {/* <Route path='v2/teams/:team_id'>
                
              </Route> */}
            </Route>

            <Route path='*' element={<Error404 />} />
          </>
        )}
      </Routes>
    </Suspense>
  );
}

export default App;
