import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Elements } from "@stripe/react-stripe-js";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { GetCountries, GetState } from "react-country-state-city";
import {
  emailValidator,
  emailWithConPatternValidator,
} from "../../utils/validationFn";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate, useParams } from "react-router-dom";
import CardElement from "../Stripe/CardElement";
import { extractEmailFromURL } from "../../utils/extractURLParams";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { removeItem } from "../../store/cartSlice";
import { NumericFormat } from "react-number-format";
import EncryptionIcon from "./EncryptionIcon";
import { createQueryString } from "../../utils/urlQueryString";

const AlertModal = ({ show, onClose, children }) => {
  const handleClose = () => {
    onClose(true);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size='lg'
      centered
      backdrop='static'
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleClose}
          size='lg'
          style={{ border: "#3CAD66", backgroundColor: "#3CAD66" }}
        >
          Okay
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// load only stripe publishable key
const loadStripePublishableKey = async (accounts) => {
  if (!accounts.length)
    return NotificationManager.warning("No payment gateway found.");

  const stripeAccount = accounts.find((account) => account.vendor === "stripe");
  if (!stripeAccount) return;

  stripeAccount.load_stripe = await loadStripe(
    stripeAccount.stripe_publishable_key
  );

  return stripeAccount;
};

const PaymentForm = ({
  cart: selectedPlans,
  coupons: appliedCoupons,
  removeCoupons,
  utm,
  grand_total,
  disabled,
}) => {
  const { store, price_slug } = useParams();
  if (!store) window.location.replace("/");

  // Payment information
  const min_phone_length = 3;
  const max_phone_length = 25;

  const [isLoading, setIsLoading] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const stateRef = useRef();
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [paymentAccountId, setPaymentAccountId] = useState(null);
  const [isDisableInput, setIsDisableInput] = useState(false);
  const [ccSettings, setCCSettings] = useState([]);
  const [isRequiredCC, setIsRequiredCC] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // these two variables are being used to submit with the payment
  const [stripe, setStripe] = useState(null);
  const [elements, setElements] = useState(null);

  const paymentObj = {
    name: "",
    email: "",
    country: "",
    country_iso2: "",
    state: "",
    phone_code: "",
    phone_number: "",
    authorized: false,
    authorized_at: new Date(),
    user_agent: navigator.userAgent,
  };

  const [obj, setObj] = useState(paymentObj);
  const [isEmailConPatter, setIsEmailConPatter] = useState(false);

  // Purpose: to display error message on the field if there is any
  const [isTouched, setIsTouched] = useState({
    name: false,
    email: false,
    phone_number: false,
  });

  // Purpose: to enable submit button
  // include only items to be validated
  const [isValid, setIsValid] = useState({
    name: false,
    email: false,
    country: false,
    state: false,
    phone_number: false,
    authorized: false,
  });

  const fetchPaymentPublicKeys = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v1/stores/${store}/payment_accounts`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );

    const { error, payloads } = await response.json();
    if (error) {
      const { message } = error;
      throw new Error(message);
    }

    setPaymentAccounts(payloads);
  };

  // Get payment publishable key
  useEffect(() => {
    fetchPaymentPublicKeys();
  }, []);

  // Populate country lists
  useEffect(() => {
    GetCountries().then((results) => {
      const countryLists = results.map((result, index) => ({
        index: index,
        value: result.id,
        label: `${result.emoji} ${result.name}`,
        name: result.name,
        iso2: result.iso2,
        iso3: result.iso3,
        phone_code: `+ ${result.phone_code}`,
      }));

      setCountryOptions(countryLists);
    });
  }, []);

  // manage vanilla html
  const handleChange = (e) => {
    const { name, value } = e.target;
    let inputValue = value ? value : "";

    let isValid = false;
    if (name === "email") {
      inputValue = inputValue.toLowerCase();

      if (emailWithConPatternValidator(inputValue)) {
        isValid = false;
        setIsEmailConPatter(true);
      } else {
        setIsEmailConPatter(false);
        isValid = emailValidator(inputValue);
      }
    } else {
      isValid = Boolean(value);
    }

    setObj((prevState) => ({
      ...prevState,
      [name]: inputValue,
    }));

    setIsValid((prevState) => ({
      ...prevState,
      [name]: isValid,
    }));
  };

  const handleBlur = (e) => {
    const { name } = e.target;

    setIsTouched((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  // manage react-select
  const handleSelectChange = (selectOptions, name) => {
    if (name === "country") {
      if (stateRef.current) {
        stateRef.current.clearValue();
      }

      setObj((prevState) => ({
        ...prevState,
        country_iso2: selectOptions.iso2,
        country: selectOptions.name,
        phone_code: selectOptions.phone_code,
        state: "",
      }));

      // Manage countries / region with states but is okay to not collect
      const excluded_lists = ["Singapore"];
      if (excluded_lists.includes(selectOptions.name)) {
        setStateOptions([]);

        setIsValid((prevState) => ({
          ...prevState,
          country: Boolean(selectOptions.name),
          state: true,
        }));

        return;
      }

      setIsValid((prevState) => ({
        ...prevState,
        [name]: Boolean(selectOptions.name),
      }));

      // populate state lists
      GetState(selectOptions.value).then((results) => {
        const stateLists = results.map((result, index) => {
          return {
            index: index,
            value: result.id,
            label: result.name,
            name: result.name,
            state_code: result.state_code,
          };
        });

        // Manage countries / region without state
        // set the state to valid so that submit button can be enable
        if (!stateLists.length) {
          setStateOptions([]);

          setIsValid((prevState) => ({
            ...prevState,
            state: true,
          }));

          return;
        }

        // Manage countries / region with state
        // set the state to invalid so that submit button can be disable
        setStateOptions(stateLists);
        setIsValid((prevState) => ({
          ...prevState,
          state: false,
        }));
      });
    } else if (name === "state") {
      // when change country, state wil return null
      // Break out of loop immediately
      // Otherwise, it will throw error.
      if (!selectOptions) return;

      setObj((prevState) => ({
        ...prevState,
        state: selectOptions.name,
      }));

      setIsValid((prevState) => ({
        ...prevState,
        [name]: Boolean(selectOptions.name),
      }));
    } else {
      setObj((prevState) => ({
        ...prevState,
        [name]: Array.isArray(selectOptions)
          ? selectOptions
          : selectOptions.value,
      }));
    }
  };

  const cardElementChangeHandler = ({ stripe, elements }) => {
    setStripe(stripe);
    setElements(elements);
  };

  // Functions
  // manage react-number-format
  const handleNumericChange = (val, name) => {
    const { floatValue, formattedValue } = val;

    if (name === "phone_number") {
      setObj((prevState) => ({
        ...prevState,
        [name]: formattedValue,
      }));

      setIsValid((prevState) => ({
        ...prevState,
        [name]: formattedValue.toString().length >= min_phone_length,
      }));

      return;
    }

    const value = parseInt(Math.round(floatValue));

    setObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setIsValid((prevState) => ({
      ...prevState,
      [name]: Boolean(value),
    }));
  };

  const handleNumericBlur = (name) => {
    setIsTouched((prevState) => ({
      ...prevState,
      [name]: true,
    }));
  };

  const handleCheck = (e) => {
    setObj((prevState) => ({
      ...prevState,
      authorized: e.target.checked,
      authorized_at: new Date(),
    }));

    setIsValid((prevState) => ({
      ...prevState,
      authorized: e.target.checked,
    }));
  };

  // Modal
  let alertElement;
  const [show, setShow] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const handleClose = () => {
    setShow(false);
    setModalContent(null);
  };

  // Submit payment to server for processing
  const paymentHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const { protocol, host } = window.location;
    let return_url = `${protocol}//${host}/stores/${store}/success`;
    let utm_params = null;
    const utmObj = {
      utm_source: utm.utm_source,
      utm_medium: utm.utm_medium,
      utm_campaign: utm.utm_campaign,
      utm_term: utm.utm_term,
      utm_content: utm.utm_content,
    };

    const utm_query_string = createQueryString(utmObj);

    if (utm_query_string) {
      return_url = return_url + `?${utm_query_string}`;
    }

    const paymentObj = {
      ...obj,
      ...utm,
      payment_account_id: paymentAccountId,
      plans: selectedPlans.map((plan) => plan.price_id),
      coupons: appliedCoupons.map((coupon) => coupon.coupon_id),
      utm: utm_params,
      client_secret: clientSecret,
      payment_intent_id: paymentIntentId,
    };

    localStorage.setItem("ppay_email", obj.email);

    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/api/v1/stores/${store}/stripe`,
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(paymentObj),
      }
    )
      .then((response) => response.json())
      .then(async (data) => {
        const { error: updateError, status, message, payloads } = data;

        if (status === "PLANS_REMOVED") {
          const removed_items = [];
          payloads.map((removed_item) => {
            removed_items.push(removed_item.name);
            dispatch(removeItem(removed_item));
          });

          if (price_slug) {
            NotificationManager.info(
              `${String(removed_items)} ${
                removed_items.length > 1 ? "have" : "has"
              } been unpublished. Please consider to explore other memberships.`
            );

            setTimeout(() => {
              navigate(`/stores/${store}`);
            }, 1500);

            return;
          }

          NotificationManager.info(
            `${String(removed_items)} ${
              removed_items.length > 1 ? "have" : "has"
            } been unpublished and thus removed from your cart. Please review and complete your purchase again.`
          );

          return;
        }

        if (status === "DISQUALIFIED_COUPONS") {
          const not_permitted_coupon_ids = [];
          const not_permitted_plans = [];

          payloads.map((coupon) => {
            not_permitted_plans.push(coupon.plan);
            not_permitted_coupon_ids.push(coupon.coupon_id);
          });

          removeCoupons(not_permitted_coupon_ids);

          const itemsElement = not_permitted_plans.map((plan, index) => {
            return <li key={index}>{plan}</li>;
          });

          alertElement = (
            <div>
              <p>
                This coupon code does not applicable to the following cart items
                for your account. Thus, has been removed from the particular
                cart items. Please review and complete your purchase again.
              </p>
              <ul>{itemsElement}</ul>
            </div>
          );

          setShow(true);
          setModalContent(alertElement);

          return;
        }

        if (updateError) {
          const { message } = updateError;
          throw new Error(message);
        }

        if (status === "CART_GRAND_TOTAL_ZERO") {
          window.location.replace(
            `${return_url}?ppay_intent_client_secret=${payloads?.ppayIntent}`
          );
          return;
        }

        // ✅ Payment Intent
        if (status === "PAYMENT_CHECKOUT_INTENT") {
          elements.submit();

          const { error } = await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
              return_url: return_url,
              payment_method_data: {
                billing_details: {
                  name: obj.name,
                  email: obj.email,
                  address: {
                    country: obj.country_iso2,
                    city: null,
                    line1: null,
                    line2: null,
                    postal_code: null,
                    state: obj.state,
                  },
                  phone: null,
                },
              },
            },
          });

          if (error.type) {
            throw Error(error.message);
          } else {
            throw Error("Something went wrong. Please try again later.");
          }
        }

        // ✅ Setup Intent
        if (status === "SETUP_CHECKOUT_INTENT") {
          elements.submit();

          const { error } = await stripe.confirmSetup({
            elements,
            clientSecret,
            confirmParams: {
              return_url: return_url,
              payment_method_data: {
                billing_details: {
                  name: obj.name,
                  email: obj.email,
                  address: {
                    country: obj.country_iso2,
                    city: null,
                    line1: null,
                    line2: null,
                    postal_code: null,
                    state: obj.state,
                  },
                  phone: null,
                },
              },
            },
          });

          if (error.type) {
            throw Error(error.message);
          } else {
            throw Error("Something went wrong. Please try again later.");
          }
        }

        // Missing logics
        if (status === "NOTHING") {
          console.log(".");
          return;
        }
      })
      .catch((error) => {
        NotificationManager.error(error.message);
      })
      .finally(() => setIsLoading(false));
  };

  const createPaymentIntent = async () => {
    try {
      const plan_ids = selectedPlans.map((plan) => plan.price_id);
      if (!plan_ids.length) return;

      const loadStripe = await loadStripePublishableKey(paymentAccounts);
      if (!loadStripe) return;

      const { payment_account_id, load_stripe } = loadStripe;
      setStripePromise(load_stripe);
      setPaymentAccountId(payment_account_id);

      const response = await fetch(
        `${process.env.REACT_APP_API_ENDPOINT}/api/v1/stores/${store}/stripe`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            payment_account_id,
            plans: plan_ids,
            coupons: appliedCoupons.map((coupon) => coupon.coupon_id),
          }),
        }
      );

      const data = await response.json();
      const { error, payloads } = data;

      if (error) {
        const { message } = error;
        throw new Error(message);
      }

      const { id: paymentIntentId, client_secret: clientSecret } = payloads;

      setClientSecret(clientSecret);
      sessionStorage.setItem("sessionClientSecret", clientSecret);

      setPaymentIntentId(paymentIntentId);
      sessionStorage.setItem("sessionPaymentIntentId", paymentIntentId);
    } catch (error) {
      const { message } = error;
      throw NotificationManager.error(message);
    }
  };

  // ✅ Create checkout intent based on email and name input
  useEffect(() => {
    const email = extractEmailFromURL();

    if (email) {
      setIsDisableInput(true);

      setObj((prevState) => ({
        ...prevState,
        email: email,
      }));

      setIsValid((prevState) => ({
        ...prevState,
        email: true,
      }));
    }

    if (isValid.name && isValid.email) {
      if (!clientSecret || !paymentIntentId) {
        createPaymentIntent();
      }
    }
  }, [obj.name, obj.email, isValid.name, isValid.email]);

  // ✅ Create checkout intent based on coupons
  useEffect(() => {
    if (isValid.name && isValid.email) {
      createPaymentIntent();
    }

    const ccSettings = appliedCoupons.map(
      (coupon) => coupon.free_trial_is_cc_required
    );

    setCCSettings(ccSettings);

    if (ccSettings.includes(true) && ccSettings.includes(false)) {
      NotificationManager.warning(
        "Each cart item should be checkout separately due to the setting of the coupon code."
      );
      return;
    }

    // Credit card is required on checkout
    const is_required_cc = appliedCoupons.every(
      (coupon) => coupon.free_trial_is_cc_required && coupon.permission === 8
    );

    setIsRequiredCC(is_required_cc);
  }, [appliedCoupons]);

  const options = {
    clientSecret,
    appearance: {
      theme: "stripe",
      rules: {
        ".Label": {
          textTransform: "uppercase",
          fontWeight: "bold",
        },
      },
    },
  };

  let formIsValid = false;
  if (
    isValid.name &&
    isValid.email &&
    isValid.country &&
    isValid.state &&
    isValid.phone_number &&
    isValid.authorized
  ) {
    formIsValid = true;
  }

  if (
    !isNaN(grand_total) &&
    grand_total > 0 &&
    !clientSecret &&
    !paymentIntentId
  ) {
    formIsValid = false;
  }

  if (ccSettings.includes(true) && ccSettings.includes(false)) {
    formIsValid = false;
  }

  return (
    <>
      {show && (
        <AlertModal show={show} onClose={handleClose}>
          {modalContent}
        </AlertModal>
      )}

      <form>
        {/* Name */}
        <div className='col-12 my-2'>
          <label htmlFor='name' className='form-label'>
            <span className='fw-bold text-uppercase'>Full Name</span>{" "}
            {!isValid.name && isTouched.name && (
              <span className='form-text text-danger'>🚨 Required!</span>
            )}
          </label>
          <input
            type='text'
            className='form-control form-control-lg'
            name='name'
            value={obj.name}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
          />
        </div>

        {/* Email Address */}
        <div className='col-12 my-2'>
          <label htmlFor='email' className='form-label'>
            <span className='fw-bold text-uppercase'>Email Address</span>{" "}
            {isEmailConPatter && !isValid.email && isTouched.email && (
              <span className='form-text text-danger'>
                🚨 Do you actually mean .com?
              </span>
            )}
            {!isEmailConPatter && !isValid.email && isTouched.email && (
              <span className='form-text text-danger'>
                🚨 A valid email address is required!
              </span>
            )}
          </label>
          <input
            type='text'
            className='form-control form-control-lg'
            name='email'
            value={obj.email}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isDisableInput || disabled}
          />

          <div className='form-text'>
            Note: If you have an existing Piranha Profits’ account, please enter
            the same email address.
          </div>
        </div>

        {/* Country / Region */}
        <div className='col-md-12 mb-3'>
          <label
            htmlFor='country'
            className='form-label fw-bold text-uppercase'
          >
            Country / Region
          </label>

          <Select
            options={countryOptions}
            placeholder='Select your country / region'
            name='country'
            onChange={(selectedOption) =>
              handleSelectChange(selectedOption, "country")
            }
            isDisabled={disabled}
          />
        </div>

        {/* State / County */}
        {stateOptions.length > 0 && (
          <div className='col-md-12 mb-3'>
            <label
              htmlFor='country'
              className='form-label fw-bold text-uppercase'
            >
              State / County
            </label>

            <Select
              options={stateOptions}
              placeholder='Select your state / county'
              name='state'
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, "state")
              }
              ref={stateRef}
              isDisabled={disabled}
            />
          </div>
        )}

        {/* Phone */}
        <div className='col-md-12'>
          {/* Phone Code */}
          <div className='row'>
            <div className='col-md-3 mb-3'>
              <label
                htmlFor='phone_code'
                className='form-label fw-bold text-uppercase'
              >
                Country Code
              </label>

              <p className='form-control form-control-lg bg-light'>
                {obj.phone_code}
              </p>
            </div>

            {/* Contact Number */}
            <div className='col-md-9 mb-3'>
              <label htmlFor='phone_number' className='form-label'>
                <span className='fw-bold text-uppercase'>Contact Number</span>{" "}
                {!isValid.phone_number && isTouched.phone_number && (
                  <span className='form-text text-danger'>🚨 Required!</span>
                )}
              </label>

              <div className='input-group'>
                <NumericFormat
                  displayType='input'
                  className='form-control form-control-lg'
                  name='phone_number'
                  decimalScale={0}
                  valueIsNumericString={true}
                  value={obj.phone_number}
                  onValueChange={(values) =>
                    handleNumericChange(values, "phone_number")
                  }
                  onBlur={() => handleNumericBlur("phone_number")}
                  disabled={disabled || !isValid.country}
                  maxLength={max_phone_length}
                />
              </div>

              <div className='form-text'>
                Note: Please fill in your contact number without country code.
              </div>
            </div>
          </div>
        </div>

        {/* Card Element */}
        {!isNaN(grand_total) && grand_total > 0 && (
          <div className='col-md-12 mb-3'>
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CardElement onCardElementChange={cardElementChangeHandler} />
              </Elements>
            )}
          </div>
        )}

        {grand_total === 0 && isRequiredCC && (
          <div className='col-md-12 mb-3'>
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CardElement onCardElementChange={cardElementChangeHandler} />
              </Elements>
            )}
          </div>
        )}

        {/* Check */}
        <div className='col-md-12 mb-3 form-check'>
          <p>
            By ticking the box below, you:
            <ol>
              <li>
                Agree to our{" "}
                <a
                  href='https://www.piranhaprofits.com/terms-of-service'
                  target='_blank'
                >
                  Terms of Service
                </a>{" "}
                &{" "}
                <a
                  href='https://www.piranhaprofits.com/privacy-policy'
                  target='_blank'
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                ⁠Have read and understood our{" "}
                <a
                  href='https://www.piranhaprofits.com/disclaimer'
                  target='_blank'
                >
                  Disclaimer and Risk Disclosure
                </a>
              </li>
            </ol>
          </p>

          <p className='text-muted fst-italic'>
            Note: If you bought a subscription, Piranha Pte Ltd will
            automatically renew it and charge your payment method until you
            cancel.
          </p>

          <input
            className='form-check-input'
            type='checkbox'
            value=''
            id='authorizedCheck'
            required
            onClick={handleCheck}
          />
          <label className='form-check-label' htmlFor='authorizedCheck'>
            I understand and agree to the above.
          </label>
        </div>

        {/* Button */}
        <div className='col-md-12 mb-3 d-grid gap-2'>
          <button
            className='btn btn-lg btn-block fw-bold rounded-pill text-white'
            style={{ backgroundColor: "#3CAD66" }}
            id='submit'
            disabled={isLoading || !formIsValid || disabled}
            onClick={paymentHandler}
          >
            {isLoading ? (
              <>
                <span
                  className='spinner-border spinner-border-sm'
                  aria-hidden='true'
                ></span>
                <span role='status'>Processing...</span>
              </>
            ) : (
              <span id='button-text'>Complete purchase</span>
            )}
          </button>
        </div>

        <div
          className='col-12 my-2 text-center m-2 fw-normal'
          style={{ fontSize: "12px" }}
        >
          <EncryptionIcon />
          Encrypted & secure payments
        </div>
      </form>
    </>
  );
};

export default PaymentForm;
